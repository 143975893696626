<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                <template #title>
                    <h5 class="mb-0">Place details</h5>
                </template>
                <a-row type="flex" :gutter="24">
                    <a-col :span="12" :lg="12" class="mb-12">
                        <a-row :gutter="[24]">
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Title" :colon="false">
                                    <a-input v-model="place.title" placeholder="eg. Barcelona"
                                        @change="handleTitleChanged" />
                                </a-form-item>
                            </a-col>
                            <!-- <a-col :span="12">
                <a-form-item class="mb-10" label="Slug" :colon="false">
                  <a-input v-if="isSlugInputDisabled && !isEdit" v-model="generatedSlugValue"
                    placeholder="Will be generated automatically" :disabled="isSlugInputDisabled"></a-input>
                  <a-input v-else-if="isEdit" v-model="generatedSlugValue" placeholder="Will be generated automatically"
                    :disabled="isSlugInputDisabled" />
                  <a-input v-else-if="!isSlugInputDisabled" v-model="generatedSlugValue"
                    placeholder="Will be generated automatically" :disabled="isSlugInputDisabled" />
                </a-form-item>
              </a-col> -->
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Subtitle" :colon="false">
                                    <a-input v-model="place.sub_title" placeholder="Awesome place to walk" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Phone Number" :colon="false">
                                    <a-input v-model="place.phone_number" placeholder="eg. +34936940953" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Website" :colon="false">
                                    <a-input v-model="place.website" placeholder="eg. robscott.com" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Booking Link" :colon="false">
                                    <a-input v-model="place.booking_link"
                                        placeholder="eg. https://www.place.com/example/" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="Highlights" :colon="false">
                                    <a-select mode="tags" placeholder="Please type" v-model="place.highlights" />
                                    <!-- <a-input v-model="place.highlightInput" placeholder="eg. fast-food" /> -->
                                </a-form-item>
                            </a-col>
                            <a-col v-if="isEdit" :span="12">
                                <a-form-item class="mb-10" label="Status" :colon="false">
                                    <a-switch v-model="place.is_active" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item class="mb-10" label="City" :colon="false">
                                    <a-select v-model="place.city_id" style="width: 100%" placeholder="City">
                                        <a-select-option v-for="city in cities" :key="city.id" :value="city.id"
                                            selected>
                                            {{ city.name }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <a-form-item label="Place's media" :colon="false">
                                    <a-upload :action="`${apiUrl}/upload/file`" list-type="picture-card"
                                        :file-list="fileList" @preview="handlePreview" @change="handleChange">
                                        <div v-if="fileList.length < 8">
                                            <a-icon type="plus" />
                                            <div class="ant-upload-text">Upload</div>
                                        </div>
                                    </a-upload>
                                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                                        <img alt="example" style="width: 100%" :src="previewImage" />
                                    </a-modal>
                                </a-form-item>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="12" :lg="12" class="mb-12">
                        <a-row :gutter="[24]">
                            <a-col :span="24">
                                <a-form-item class="mb-10" label="Address" :colon="false">
                                    <a-input v-model="place.address" placeholder="eg. Barcelona" :disabled="true" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="24">
                                <GmapMap :center="center" :zoom="12" map-style-id="roadmap" :options="mapOptions"
                                    style="width: 100%; height: 300px" ref="mapRef" @click="handleMapClick">
                                    <GmapMarker :position="marker.position" :clickable="true" :draggable="true"
                                        @drag="handleMarkerDrag" @click="panToMarker" />
                                </GmapMap>
                                <GmapAutocomplete placeholder="Search Location..." @place_changed='setPlace'
                                    class="map-search-input" />
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <!-- Error Message Display -->
                <div v-if="!isSlugInputDisabled" class="alert alert-warning" role="alert">
                    <p class="font-bold">Oops</p>
                    <p>Slug already exists. Please choose a different one.</p>
                </div>
            </a-card>
            <a-button type="primary" html-type="submit" style="margin-top: 20px;"> Submit </a-button>
        </a-form>
    </div>
</template>

<script>
import slug from "slug";
import router from "../../router/index.js";
import placeAPI from "../../api/place.js";
import cityAPI from "../../api/city.js";

export default {
    data() {
        return {
            apiUrl: process.env.VUE_APP_BASE_URL,
            place: {
                id: null,
                title: "",
                slug: "",
                sub_title: "",
                address: "",
                phone_number: "",
                booking_link: "",
                google_place_id: "",
                longitude: 0,
                latitude: 0,
                highlights: [],
                media: [],
                working_hours: null,
                google_media: null,
                is_active: false,
            },
            cities: [],
            googleMediaFromDB: false,
            marker: {
                position: {
                    lat: 40.423951,
                    lng: -3.671837,
                    place_id: "ChIJ6SvOeZMpQg0R_nB10ko1KQk",
                },
            },
            center: { lat: 40.423951, lng: -3.671837 },
            mapOptions: {
                disableDefaultUI: true,
            },
            storedPhotosOfThePlace: null,
            placeEditData: null,
            titleChanged: false,
            isSlugInputDisabled: true,
            generatedSlugValue: "",
            isEdit: false,
            previewVisible: false,
            fileList: [],
            form: this.$form.createForm(this, { name: "editPlace" }),
            previewImage: null,
        };
    },
    methods: {
        async getPlaceBySlug(slug) {
            return placeAPI.getPlaceBySlug(slug);
        },
        handleCancel() {
            this.previewVisible = false;
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await this.getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },
        handleChange({ fileList }) {
            this.fileList = fileList;
        },
        async getCities() {
            return cityAPI.getCities();
        },
        onChangeClick(e) {
            console.log(e);
        },
        handleTitleChanged() {
            this.titleChanged = true;
        },
        //sets the position of marker when dragged
        handleMarkerDrag(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        },
        //Moves the map view port to marker
        panToMarker() {
            this.$refs.mapRef.panTo(this.marker.position);
        },
        setPlace(place) {
            this.marker.position = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            this.center = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            this.getPlaceDetailsByPlaceId(place.place_id);
        },
        //Moves the marker to click position on the map
        handleMapClick(e) {
            this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            // this.getPlaceDetails(e.latLng);
            this.getPlaceDetailsByPlaceId(e.placeId);
        },
        // detects location from browser
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.marker.position = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.panToMarker();
            });
        },
        // async getPlaceFullDetailsByPlaceId(placeId) {
        //   return utilsAPI.getPlaceDetailsByPlaceId(placeId);
        // },
        // Example code Google Maps
        getPlaceDetailsByPlaceId(placeId) {
            const service = new google.maps.places.PlacesService(
                this.$refs.mapRef.$mapObject
            );
            service.getDetails(
                {
                    placeId: placeId,
                    fields: [
                        "name",
                        "formatted_address",
                        "place_id",
                        "geometry",
                        "opening_hours",
                        "photos",
                        "website",
                        "formatted_phone_number",
                    ],
                },
                (place, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        console.log(place);
                        this.place.address = place.formatted_address;
                        this.place.title = place.name;
                        this.generatedSlugValue = slug(place.name);
                        this.marker.position.place_id = placeId;
                        this.googleMediaFromDB = false;
                        if (place.formatted_phone_number) {
                            this.place.phone_number = place.formatted_phone_number;
                        } else {
                            this.place.phone_number = "";
                        }
                        if (place.website) {
                            this.place.website = place.website;
                        } else {
                            this.place.website = "";
                        }
                        if (place.opening_hours) {
                            this.place.working_hours = place.opening_hours.periods;
                        } else {
                            this.place.working_hours = [];
                        }
                        if (place.photos) {
                            this.place.google_media = place.photos;
                            this.storedPhotosOfThePlace = place.photos;
                        } else {
                            this.place.google_media = [];
                        }
                    } else {
                        console.error("Place details request failed due to " + status);
                    }
                }
            );
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err) => {
                if (!err) {
                    for (let file of this.fileList) {
                        this.place.media.push(file.response.data.id);
                    }
                    this.place.slug = this.generatedSlugValue;
                    this.place.longitude = this.marker.position.lng;
                    this.place.latitude = this.marker.position.lat;
                    this.place.google_place_id = this.marker.position.place_id;
                    this.place.phone_number = this.place.phone_number;
                    if (!this.googleMediaFromDB && this.place.google_media) {
                        let photoDatas = this.place.google_media;
                        if (!this.isSlugInputDisabled) {
                            photoDatas = this.storedPhotosOfThePlace;
                        }
                        this.place.google_media = [];

                        for (let i = 0; i < photoDatas.length; i++) {
                            // console.log(photoDatas[i].getUrl());
                            console.log(photoDatas[i].photo_reference);
                            this.place.google_media.push({
                                url: photoDatas[i].getUrl({ maxWidth: 5000, maxHeight: 5000 }), // TODO: fix for getting reference and make the url
                                size_y: photoDatas[i].height,
                                size_x: photoDatas[i].width,
                            });
                        }
                    }

                    // Request to create or update data :)
                    if (!this.isEdit) {
                        placeAPI
                            .createPlace(this.place)
                            .then((responseData) => {
                                if (responseData.status == 200) {
                                    router.push("/places/places-list");
                                }
                            })
                            .catch((error) => {
                                if (error.code === 400) {
                                    this.isSlugInputDisabled = false;
                                }
                                this.place.google_media = [];
                            });
                    } else {
                        placeAPI
                            .updatePlace(this.place)
                            .then((responseData) => {
                                if (responseData.status == 200) {
                                    router.push("/places/places-list");
                                }
                            })
                            .catch((error) => {
                                if (error.code === 400) {
                                    this.isSlugInputDisabled = false;
                                }
                                this.place.google_media = [];
                            });
                    }
                }
            });
        },
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            this.placeEditData = await this.getPlaceBySlug(this.$route.params.id); // get place data according to slug
            this.isEdit = true;
            this.place.id = this.placeEditData.id;
            this.place.title = this.placeEditData.title;
            this.place.sub_title = this.placeEditData.sub_title;
            this.place.website = this.placeEditData.website;
            this.generatedSlugValue = this.placeEditData.slug;
            this.place.address = this.placeEditData.address;
            this.place.booking_link = this.placeEditData.booking_link;
            this.place.phone_number = this.placeEditData.phone_number;
            this.marker.position.place_id = this.placeEditData.google_place_id;
            this.marker.position.lng = this.placeEditData.longitude;
            this.marker.position.lat = this.placeEditData.latitude;
            this.center.lat = this.placeEditData.latitude;
            this.center.lng = this.placeEditData.longitude;
            this.place.highlights = this.placeEditData.highlights;
            this.place.is_active = this.placeEditData.is_active;
            this.place.working_hours = this.placeEditData.working_hours;
            this.place.google_media = this.placeEditData.google_media;
            this.googleMediaFromDB = true;
            for (let i = 0; i < this.placeEditData.media.length; i++) {
                let media = this.placeEditData.media[i];
                let name = media.url.split("+")[media.url.split("+").length - 1];
                this.fileList.push({
                    uid: media.id,
                    response: {
                        data: {
                            id: media.id,
                        },
                    },
                    status: "done",
                    name: name,
                    url: "https://" + media.url,
                });
            }
        }
        const dataCities = await this.getCities();
        this.cities = dataCities.cities;
    },
    /* computed: {
      generatedSlug() {
        return slug(this.place.title);
      },
    },
    watch: {
      generatedSlug(newValue) {
        if (this.titleChanged) {
          this.generatedSlugValue = slug(newValue);
        }
      },
    },*/
};
</script>

<style lang="scss" scoped>
.product-img {
    border-radius: 12px;
}

.alert {
    font-weight: bold;
    background-color: #feebc8;
    border-left: 4px solid #f59e0b;
    color: #f59e0b;
    padding: 0.5rem;
}

.alert p.font-bold {
    font-weight: bold;
}

.map-search-input {
    width: 100%;
    padding: 10px;
    font-weight: 600;
    height: 40px;
    margin-top: 10px;
    border-radius: 6px;
    color: #252424;
    padding: 4px;
    font-family: inherit;
    border: 1px solid #D9D9D9;
    touch-action: manipulation;
}
</style>
